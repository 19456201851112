import { Whatsapp } from './components/whatsapp'
import Router from './router/index'


function App() {

  return (
    <>
      <Router />
      <Whatsapp />  
    </>
  );
}

export default App;