import { Icon } from 'semantic-ui-react';


const Whatsapp = () => {
  const phoneNumber = "+35699998330";
  const message = ""
  return (
    <div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 999 }}>
      <a
        href={`https://wa.me/${phoneNumber}?text=${message}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "60px",
          backgroundColor: "#25D366",
          borderRadius: "30px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          color: "white",
          fontSize: "24px",
          textDecoration: "none",
        }}
      >
        <Icon name="whatsapp" size={"large"} />

      </a>
    </div>
  )
}

export { Whatsapp };